@mixin default-font {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
}

@mixin link-hover {
  border: 1px solid #0077ad !important;
  background-color: #d9ebf3 !important;
  color: #0077ad !important;
}

.main-container {
  .sidebar-wrapper {
    // position: fixed;
    .profile-info {
      .name-email-container {
        max-width: 80% !important;
      }

      .name {
        @include default-font;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #003765;
        text-transform: capitalize;
      }

      p {
        @include default-font;
      }
    }

    .nav-item {
      margin-bottom: 15px;

      a {
        border: 1px solid #bfddea;
        border-radius: 50px;
        padding: 12px 16px !important;
        font-family: "Open Sans", sans-serif !important;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #4d7393 !important;
        display: flex;
        transition: all ease-out 0.15s;

        &:hover {
          font-weight: 700 !important;
          font-size: 16px;
          @include link-hover;
          opacity: 0.7;
          transition: all ease-in 0.15s;
        }

        &.router-link-active {
          @include link-hover;
        }

        .arrow-icon {
          margin-left: auto;
          margin-right: 8px;
        }
      }
    }
  }

  .box-white-wrapper {
    background-color: #fff;
    margin-left: 4em;
    box-shadow: 0px 7px 30px -10px rgba(0, 119, 173, 0.08);
    border-radius: 8px;
    padding: 24px;
  }

  .page-title {
    margin-left: 1.5em;
    margin-bottom: 30px;
    @include default-font;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    color: #003765;

    h1 {
      @include default-font;
      font-weight: 700;
      font-size: 40px;
      line-height: 120%;
    }
  }
}
