.two-fa {
  hr {
    border-top: 1px solid #000000 !important;
    opacity: 0.2 !important;
    margin: 0px !important;
  }

  .text-synlab-primary,
  span:not(.fas, .badge),
  p {
    @include default-font;
    font-size: 18px;
    line-height: 28px;
    color: #003765 !important;
  }

  .fas {
    color: #003765 !important;
  }

  .text-link {
    @include default-font;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 5%;
    text-transform: uppercase;
  }

  .text-link:not(.disabled a) {
    color: #003765 !important;
  }

  .disabled a,
  .disabled a:hover {
    color: rgb(0 66 97 / 50%) !important;
  }

  .disabled p,
  .disabled span {
    color: rgb(0 55 101 / 50%) !important;
  }

  .disabled .badge-success {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .tab-cont {
    margin: 0px !important;
  }

  .two-fa-wrapper-grid_sms {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "grid-sms_sec-1 grid-sms_sec-2"
      "grid-sms_sec-3 ."
      "grid-sms_sec-4 grid-sms_sec-4";
  }

  .grid-sms_sec-1 {
    grid-area: grid-sms_sec-1;
  }

  .grid-sms_sec-2 {
    grid-area: grid-sms_sec-2;
    justify-self: end;
  }
  .grid-sms_sec-3 {
    grid-area: grid-sms_sec-3;
  }

  .grid-sms_sec-4 {
    grid-area: grid-sms_sec-4;
  }

  .two-fa-wrapper-grid_auth {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "grid-auth_sec-1 grid-auth_sec-2"
      "grid-auth_sec-3 ."
      "grid-auth_sec-4 grid-auth_sec-4";
  }

  .grid-auth_sec-1 {
    grid-area: grid-auth_sec-1;
  }

  .grid-auth_sec-2 {
    grid-area: grid-auth_sec-2;
    justify-self: end;
  }

  .grid-auth_sec-3 {
    grid-area: grid-auth_sec-3;
  }

  .grid-auth_sec-4 {
    grid-area: grid-auth_sec-4;
    padding: 0.9rem 5rem;
    text-align: center;
  }

  .field-container {
    padding-bottom: 2.5rem !important;

    p {
      margin-bottom: 0 !important;
    }
  }

  input.form-control {
    background: #ffffff;
    border: 1px solid #bfddea !important;
    border-radius: 8px;
    padding: 15px 20px;
    height: 54px;
    @include default-font;
    color: #003765;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &:disabled {
      background-color: #eaeaea !important;
      border: 1px solid #d8d8d8;
    }
  }

  .resend-section {
    padding-top: 12px !important;
  }

  .badge.badge-success {
    @include default-font;
    font-size: 12px !important;
  }

  #submitBtn {
    @include btn-default-styles;
  }
}

@media only screen and (max-width: 991px) {
  .two-fa {
    padding: 0 !important;

    .account-profile-container {
      margin-left: 0;
    }

    .page-title {
      margin-left: 0;

      h1 {
        font-size: 32px;
      }
    }

    h4.title {
      background-color: transparent !important;
    }

    .btn-actions {
      flex-direction: row-reverse !important;
      justify-content: flex-end;
      padding-left: 0 !important;

      button {
        max-width: 300px;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .two-fa {
    .two-fa-wrapper-grid_sms {
      grid-template-columns: 1fr !important;
      grid-template-rows: auto !important;
      grid-template-areas:
        "grid-sms_sec-1"
        "grid-sms_sec-3"
        "grid-sms_sec-4"
        "grid-sms_sec-2" !important;
      padding-bottom: 2.5rem !important;
      padding-top: 2.3rem !important;
    }

    .two-fa-wrapper-grid_auth {
      grid-template-columns: 1fr !important;
      grid-template-rows: auto !important;
      grid-template-areas:
        "grid-auth_sec-1"
        "grid-auth_sec-3"
        "grid-auth_sec-4"
        "grid-auth_sec-2" !important;
    }

    .grid-sms_sec-2 {
      justify-self: start;
    }

    .grid-auth_sec-2 {
      justify-self: start;
    }

    .grid-auth_sec-4 {
      padding-left: 0;
      padding-right: 0;
      text-align: start;
    }

    .qr-section {
      padding: 8px 0 12px 0;
    }

    .field-container {
      padding-bottom: 0.5rem !important;
    }

    .sms-text-1 {
      padding-bottom: 2.5rem !important;
    }

    .sms-text-2 {
      padding-bottom: 0 !important;
    }

    .resend-section {
      a {
        margin-left: 0 !important;
      }
    }
  }
}
