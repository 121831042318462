:host {
  position: fixed;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: animloader 1s linear 1;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  opacity: 0;
  animation: enter;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #F9B232;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.loader::after {
  animation-delay: 1s;
}
@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes enter {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
