@import "./variables";
@font-face {
  font-family: "Open Sans";
  src: url("/assets/font/OpenSans-VariableFont_wdth,wght.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
/*
* text colors
*/
.pl-text-color-1 {
  color: $cs1;
}
.pl-text-color-2 {
  color: $cs2;
}
.pl-text-color-3 {
  color: $cs3;
}
.pl-text-color-4 {
  color: $cs4;
}
.pl-text-color-5 {
  color: $cs5;
}

/*
* borders
*/
.pl-border-1 {
  border: 1px solid $cs1;
}

/*
* box-shadow
*/
.pl-box-shadow-1 {
  // -webkit-box-shadow: 0px 0px 5px 0px rgba(121, 133, 142, 0.42);
  // -moz-box-shadow: 0px 0px 5px 0px rgba(121, 133, 142, 0.42);
  // box-shadow: 0px 0px 5px 0px rgba(121, 133, 142, 0.42);
  // transition: box-shadow ease-in 0.12s;
  background-color: #fff;
  box-shadow: none;
  margin-left: 4em;
}
.pl-box-shadow-1-hover:hover {
  // -webkit-box-shadow: 0px 0px 15px 0px rgba(121, 133, 142, 0.52);
  // -moz-box-shadow: 0px 0px 15px 0px rgba(121, 133, 142, 0.52);
  // box-shadow: 0px 0px 15px 0px rgba(121, 133, 142, 0.52);
  // transition: box-shadow ease-in 0.12s;
}
.pl-box-shadow-2 {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(121, 133, 142, 0.31);
  -moz-box-shadow: 0px 5px 10px 0px rgba(121, 133, 142, 0.31);
  box-shadow: 0px 5px 10px 0px rgba(121, 133, 142, 0.31);
}

/*
* background colors
*/
.pl-bg-1 {
  background-color: $cs6;
}
.pl-bg-2 {
  background-color: $cs0;
}
.pl-bg-3 {
  background-color: $cs7;
}

/* ===============
  Utility classes
================= */
/* ================
  Font size scale
================= */
.f-1 {
  font-size: $font-1;
}
.f-2 {
  font-size: $font-2;
}
.f-3 {
  font-size: $font-3;
}
.f-4 {
  font-size: $font-4;
}
.f-5 {
  font-size: $font-5;
}
.f-6 {
  font-size: $font-6;
}
.f-7 {
  font-size: $font-7;
}
.f-8 {
  font-size: $font-8;
}
.f-9 {
  font-size: $font-9;
}
.f-10 {
  font-size: $font-10;
}

// styles for new designs
body {
  background-color: #f2f8fb !important;
}
.main-container {
  max-width: 1440px;
  margin: 0 auto;
  display: block;

  .grid-container {
    display: grid;
    grid-template-columns: minmax(320px, 420px) 1fr;
  }
}
@import "./styles/sidebar";
@import "./styles/account-profile";
@import "./styles/security";
@import "./styles/notification-settting";
@import "./styles/two-fa-auth";
@import "./styles/others.scss";

@import "./media.scss";
