.change-password {
  .page-title {
    margin-left: 1.5em;
    margin-bottom: 30px;
    @include default-font;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    color: #003765;

    h1 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 120%;
    }
  }

  ul {
    list-style: disc;
    padding: 40px;
  }

  .text-synlab-primary {
    @include default-font;
    font-size: 18px;
    line-height: 28px;
    color: #003765;
    letter-spacing: -1%;
  }

  .field-label {
    @include default-font;
    font-size: 16px;
    line-height: 24px;
    color: #003765 !important;
    font-weight: 700;
  }

  input.form-control {
    background: #ffffff;
    border: 1px solid #bfddea !important;
    border-radius: 8px;
    padding: 15px 20px;
    height: 54px;
    @include default-font;
    color: #003765;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &:disabled {
      background-color: #eaeaea !important;
      border: 1px solid #d8d8d8;
    }
  }

  #submitAccountFormBtn {
    @include btn-default-styles;
  }
}

@media only screen and (max-width: 991px) {
  .change-password {
    padding: 0 !important;

    .account-profile-container {
      margin-left: 0;
    }

    .page-title {
      margin-left: 0;

      h1 {
        font-size: 32px;
      }
    }

    h4.title {
      background-color: transparent !important;
    }

    .btn-actions {
      flex-direction: row-reverse !important;
      justify-content: flex-end;
      padding-left: 0 !important;

      button {
        max-width: 300px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .grid-container {
    .change-password {
      .btn-actions {
        flex-direction: column !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        button {
          max-width: none;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  #submitAccountFormBtn {
    width: 100%;
  }
}
