@mixin default-font {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  color: #003765;
}

.accept-terms,
.accept-policy,
.prevention-wellness {
  .text-synlab-primary {
    @include default-font;
  }

  .synlab-table {
    border: none;
    border-radius: none;
    box-shadow: none;

    thead th {
      vertical-align: top;
      border: none !important;
      @include default-font;
      font-weight: 700;
    }

    thead {
      background-color: #f2f8fb;
      border-radius: 10px !important;
    }

    tbody {
      tr td {
        @include default-font;
        border: none !important;
        border-bottom: 1px solid #e6e6e6 !important;
        padding: 15px 15px !important;
      }

      tr:last-child td {
        border-bottom: none !important;
      }

      tr:first-child td {
        padding-top: 20px !important;
      }
    }
  }
}

.prevention-wellness {
  .switch {
    width: 40px;
    height: 24px;

    input {
      &:not(:checked) + .slider {
        box-sizing: border-box;
        border: 2.5px solid #808080;
        background-color: #ffffff;
        &:before {
          background-color: #808080;
          bottom: 1.5px;
          left: 2.3px;
        }
      }

      &:not(:checked):disabled + .slider {
        border-color: rgba(128, 128, 128, 0.4);
        &:before {
          background-color: rgba(128, 128, 128, 0.4) !important;
        }
      }

      &[disabled]:checked + .slider {
        cursor: default !important;
        background-color: rgba(104, 151, 28, 0.4) !important;
      }
    }

    input:checked + .slider {
      background-color: #68971c !important;
      &:before {
        left: -6.5px !important;
      }
    }
    .slider:before {
      width: 16px;
      height: 16px;
    }
  }

  .pw-title {
    margin-bottom: 0.5rem;
  }

  .pw-checkbox {
    padding-top: 0.5rem;
  }
}

@media only screen and (max-width: 991px) {
  .accept-terms,
  .accept-policy,
  .prevention-wellness {
    padding: 0 !important;

    .account-profile-container {
      margin-left: 0;
    }

    .page-title {
      margin-left: 0;

      h1 {
        font-size: 32px;
      }
    }
  }
}
