@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  font-weight: 300;
}

h3 {
  color: lighten(#000000, 25%);
  font-weight: 400;
}

hr {
  margin: 25px 0 30px 0;
}

.logo {
  width: 125px;
}
.card-header {
  font-weight: bold;
}
.nav-color {
  background-color: #4572b7;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.nav-link {
  color: #ffff;
}

.nav-link:hover {
  font-weight: bolder;
}
.nav-height {
  padding-top: 0px;
  max-height: 61px;
}
.navbar-header {
  /* padding-left: 20px; */
}
.nav-height .navbar-nav {
  margin-top: 8px;
}
.col {
  height: 200px;
}

.d-flex {
  display: flex;
}

.flx-1 {
  flex: 1;
}

.mrg-auto {
  margin: auto;
}

.index {
  margin-top: 70px;
}

.user-list {
  background-color: #f3f3f3;
  width: 100%;
  height: 100%;
  display: flex;
}

.user-filter {
  margin: 18px 0 25px 0;
}

.mrg-btn-3 {
  margin-bottom: 20px;
}

.home-nav {
  margin-left: 40px;
}

.navbar-appname {
  /* color: #ffffff;
  text-align: center; */
  font-size: smaller;
  margin-top: -4px;
}

/*toogle*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.page-box {
  background-color: #ffffff;
  margin-top: 30px;
  padding: 30px 20px;
  -webkit-box-shadow: 0px 0px 44px -14px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 44px -14px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 44px -14px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  height: auto !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
