@keyframes ldio-93llrimgkk9 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-93llrimgkk9 div {
  position: absolute;
  width: 73px;
  height: 73px;
  border: 7px solid #003765;
  border-top-color: transparent;
  border-radius: 50%;
  animation: ldio-93llrimgkk9 1.25s linear infinite;
  top: 50px;
  left: 50px;
  box-sizing: content-box;
}
.loadingio-spinner-rolling-zxjk4r96u5p {
  width: 70px;
  height: 70px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-93llrimgkk9 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.7);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
