$transition-duration: 0.15s;

@mixin default-font {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
}

@mixin btn-default-styles {
  justify-content: center;
  align-items: center;
  padding: 15px 24px 15px 20px;
  background: #003765;
  border-radius: 68px;
  @include default-font;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none !important;
  transition: all ease-out $transition-duration;
  display: inline-flex !important;

  &:hover:not(:disabled) {
    opacity: 0.8;
    transition: all ease-in $transition-duration;
  }
}

html,
body {
  height: auto !important;
  min-height: 100vh;
}

body {
  background-image: url("../../../assets/images/registerv2/synlab-icon-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
}

[name="phone"] input[type="tel"] {
  box-shadow: none !important;
}

.personal-information {
  h4 {
    color: #003765;
    @include default-font;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }

  .page-title {
    margin-left: 1.5em;
    margin-bottom: 30px;
    @include default-font;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    color: #003765;

    h1 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 120%;
    }
  }

  .account-profile-container {
    background-color: #fff;
    margin-left: 4em;
    box-shadow: 0px 7px 30px -10px rgba(0, 119, 173, 0.08);
    border-radius: 8px;
    padding: 24px;

    .synlab-id-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      padding-left: 24px;
      padding-right: 24px;
      border: 1px solid #d9ebf3;
      border-radius: 8px;

      .barcode-container {
        display: flex;
        justify-content: flex-end;

        svg {
          max-width: 138px;
          max-height: 90px;
        }
      }

      .qr-wrapper {
        justify-self: flex-end;
      }

      .synlabid-text-value {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;

        h4 {
          font-size: 18px;
        }
      }
    }

    .claim-value-wrapper {
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #d9ebf3;

      .field-label {
        width: auto;
        @include default-font;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .field-value {
        margin-left: auto;
        @include default-font;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .switch {
      width: 40px;
      height: 24px;

      input {
        &:not(:checked) + .slider {
          box-sizing: border-box;
          border: 2.5px solid #808080;
          background-color: #ffffff;
          &:before {
            background-color: #808080;
            bottom: 1.5px;
            left: 2.3px;
          }
        }

        &:not(:checked):disabled + .slider {
          border-color: rgba(128, 128, 128, 0.4);
          &:before {
            background-color: rgba(128, 128, 128, 0.4) !important;
          }
        }

        &[disabled]:checked + .slider {
          cursor: default !important;
          background-color: rgba(104, 151, 28, 0.4) !important;
        }
      }

      input:checked + .slider {
        background-color: #68971c !important;
        &:before {
          left: -6.5px !important;
        }
      }
      .slider:before {
        width: 16px;
        height: 16px;
      }
    }
  }

  .edit-button-container {
    display: flex;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-top: 10px;
    align-items: center;

    .btn {
      @include btn-default-styles;
    }

    .edit-btn-container {
      margin-left: auto;
    }

    .anonymous-container {
      height: 52px;
      display: flex;
      align-items: center;

      label {
        @include default-font;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #003765;
      }
    }
  }

  .contact-info-wrapper {
    margin-top: 30px;
  }

  .block-wrapper {
    &.ward {
      .row {
        .col-12 {
          &:last-child {
            .claim-value-wrapper {
              border-bottom: none !important;
            }
          }
        }
      }
    }
  }

  .ward-container,
  .pet-list-container,
  .teacher-list-container {
    .text-synlab-secondary {
      @include btn-default-styles;
      max-width: 190px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .delete-account-container {
    padding-top: 30px;

    .btn {
      padding-left: 0;
      padding-right: 0;
      @include default-font;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #be1622;
    }
  }

  // styles for edit view
  .form-group {
    .text-synlab-primary {
      @include default-font;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #003765;
    }

    input.form-control {
      background: #ffffff;
      border: 1px solid #bfddea;
      border-radius: 8px;
      padding: 15px 20px;
      height: 54px;
      @include default-font;
      color: #003765;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      &:disabled {
        background-color: #eaeaea !important;
        border: 1px solid #d8d8d8;
      }
    }

    app-date-picker.form-control {
      background: #ffffff;
      border: 1px solid #bfddea;
      border-radius: 8px;
      padding: 15px 20px;
      height: 54px;
      @include default-font;
      color: #003765;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      input {
        padding-left: 10px;
        @include default-font;
        color: #003765;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  #submitAccountFormBtn {
    @include btn-default-styles;
  }

  .cancel-btn {
    @include btn-default-styles;
    background-color: #fff;
    color: #616161;
    padding-left: 24px;
    border: 2px solid #e7e7e7;
  }
}

.account-add-ward,
.account-set-password-container {
  .form-group {
    .text-synlab-primary {
      @include default-font;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #003765;
    }

    input.form-control {
      background: #ffffff;
      border: 1px solid #bfddea;
      border-radius: 8px;
      padding: 15px 20px;
      height: 54px;
      @include default-font;
      color: #003765;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      &:disabled {
        background-color: #eaeaea !important;
        border: 1px solid #d8d8d8;
      }
      &.border-danger {
        border: 1px solid #dc3545;
      }
    }
  }

  p {
    @include default-font;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #003765;
  }

  .linked-account-wrapper,
  .address-edit-wrapper {
    h4 {
      color: #003765;
      @include default-font;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }
  }

  input.mat-tooltip-trigger {
    background: #ffffff;
    border: 1px solid #bfddea;
    border-radius: 8px;
    padding: 15px 20px;
    height: 54px;
    @include default-font;
    color: #003765;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;

    &:disabled {
      background-color: #eaeaea !important;
      border: 1px solid #d8d8d8;
    }
  }

  .btn-add-synlabid {
    @include btn-default-styles;
    border: 0px !important;
  }

  .btn-cancel,
  .btn-back {
    @include btn-default-styles;
    background-color: #fff;
    color: #616161;
    padding-left: 24px;
    border: 2px solid #e7e7e7;
    margin-right: 10px;
  }

  .btn-save {
    @include btn-default-styles;
    border: 0px !important;
    border-radius: 68px !important;
  }

  app-date-picker.form-control {
    background: #ffffff;
    border: 1px solid #bfddea;
    border-radius: 8px;
    padding: 15px 20px;
    height: 54px;
    @include default-font;
    color: #003765;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    input {
      padding-left: 10px;
      @include default-font;
      color: #003765;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.account-delete {
  p {
    @include default-font;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #003765;
  }

  .btn-outline-danger {
    @include btn-default-styles;
    background-color: #be1622;
    border: 0px !important;
  }

  .btn-cancel {
    @include btn-default-styles;
    background-color: #fff;
    color: #616161;
    padding-left: 24px;
    border: 2px solid #e7e7e7;
    margin-right: 10px;
  }
}

.account-add-pet {
  input.form-control,
  .mat-select {
    background: #ffffff;
    border: 1px solid #bfddea;
    border-radius: 8px;
    padding: 15px 20px;
    height: 54px;
    @include default-font;
    color: #003765;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 0;

    &:disabled {
      background-color: #eaeaea !important;
      border: 1px solid #d8d8d8;
    }
  }

  .mat-select-trigger {
    height: 50px;
  }

  .pet-description {
    @include default-font;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #003765;
  }

  label.form-text {
    @include default-font;
  }

  #submitAccountFormBtn {
    @include btn-default-styles;
  }

  .btn-cancel,
  .btn-back {
    @include btn-default-styles;
    background-color: #fff;
    color: #616161;
    padding-left: 24px;
    border: 2px solid #e7e7e7;
    margin-right: 10px;
  }

  .btn-delete {
    @include btn-default-styles;
    color: #be1622;
    background-color: transparent;
    &:hover {
      background-color: #be1622;
      color: #fff;
    }
  }
}

// media queries
@media only screen and (max-width: 1500px) {
  .main-container {
    padding-left: 20px;
    padding-right: 30px;

    .grid-container {
      grid-template-columns: minmax(280px, 350px) 1fr;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .main-container {
    .grid-container {
      grid-template-columns: minmax(280px, 320px) 1fr;
    }
  }

  .personal-information {
    .account-profile-container {
      margin-left: 2em;
    }

    .page-title {
      margin-left: 0.8em;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .main-container {
    .grid-container {
      grid-template-columns: 280px 1fr;
    }
  }
}

@media only screen and (max-width: 991px) {
  html,
  body {
    overflow-x: hidden;
  }

  .main-container {
    padding-right: 20px;

    .grid-container {
      grid-template-columns: 100%;

      .sidebar-container {
        margin-bottom: 30px;
      }

      .personal-information {
        padding: 0 !important;

        .account-profile-container {
          margin-left: 0;
        }

        .page-title {
          margin-left: 0;

          h1 {
            font-size: 32px;
          }
        }

        h4.title {
          background-color: transparent !important;
        }

        .btn-actions {
          flex-direction: row-reverse !important;
          justify-content: flex-end;
          padding-left: 0 !important;

          button {
            max-width: 300px;
          }
        }
      }
    }

    .box-white-wrapper,
    .page-title {
      margin-left: 0;
    }

    .synlab-id-title {
      background-color: transparent !important;
      text-align: center;
    }

    .barcodeWrapper {
      svg {
        & > rect {
          fill: transparent !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .main-container {
    padding-right: 20px;

    .account-profile {
      .personal-information {
        .flex-column {
          flex-direction: row !important;

          .field-label {
            padding-right: 30px;
          }
        }
      }
    }

    .grid-container {
      .personal-information {
        .btn-actions {
          flex-direction: column !important;
          padding-left: 0 !important;
          padding-right: 0 !important;

          button {
            max-width: none;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .account-add-ward {
    .btn-back,
    .btn-cancel {
      margin: 0 !important;
      margin-top: 20px !important;
    }
  }
}
