$show: ".tooltip-description.collapse.show";
$collapsing: ".tooltip-description.collapsing";
$collapse: ".tooltip-description.collapse";

@mixin border-bottom-blue {
  border-bottom: 1px solid #bfddea !important;
}

.notification-settings {
  .page-title {
    margin-left: 1.5em;
    margin-bottom: 30px;
    @include default-font;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    color: #003765;

    h1 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 120%;
    }
  }

  h6 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #003765;
  }

  .text-synlab-primary {
    @include default-font;
    font-size: 18px;
    line-height: 28px;
    color: #003765;
  }

  .mat-radio-label-content,
  .mat-checkbox-label,
  .btn-label {
    @include default-font;
    font-size: 18px !important;
    line-height: 28px !important;
    color: #003765 !important;
  }

  .mat-radio-outer-circle,
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #0077ad !important;
  }

  .mat-radio-inner-circle {
    background-color: #0077ad !important;
  }

  .notif-claim-value {
    @include border-bottom-blue;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .notif_comp-1,
  .notif_comp-2 {
    padding-top: 5px;
  }

  .send_notif_trigger-form .notif-claim-value,
  .consent_trigger-form .notif-claim-value {
    &:last-child {
      border-bottom: none !important;
    }
  }

  .radio-btn-col.notif-claim-value {
    &:last-child {
      border-bottom: none !important;
    }
  }

  .consent_trigger-form {
    padding-top: 5px !important;
  }

  .info-icon {
    flex: 1;
    img {
      cursor: pointer;
      max-width: 20px;
      width: 20px;
      height: 20px;
      float: right;
    }
  }

  //information description tooltip
  .btn-label:has(#{$collapse}) + .info-icon > .active-icon img,
  .btn-label:has(#{$collapsing}, #{$show}) + .info-icon > .default-icon img {
    display: none;
  }

  .btn-label:has(#{$show}) + .info-icon > .active-icon img {
    display: block;
  }

  .tooltip-wrapper {
    .close,
    .close:active,
    .close:focus {
      outline: none;
      color: #ffffff;
      opacity: 1;
      line-height: 1px;
      font-weight: 400;
      margin: 5px 0px 5px 10px !important;
    }
  }

  .consent-checkbox,
  .info-icon {
    align-self: baseline;
  }

  .save-label {
    color: #0077ad;
  }

  .switch {
    width: 40px;
    height: 24px;

    input {
      &:not(:checked) + .slider {
        box-sizing: border-box;
        border: 2.5px solid #808080;
        background-color: #ffffff;
        &:before {
          background-color: #808080;
          bottom: 1.5px;
          left: 2.3px;
        }
      }

      &:not(:checked):disabled + .slider {
        border-color: rgba(128, 128, 128, 0.4);
        &:before {
          background-color: rgba(128, 128, 128, 0.4) !important;
        }
      }

      &[disabled]:checked + .slider {
        cursor: default !important;
        background-color: rgba(104, 151, 28, 0.4) !important;
      }
    }

    input:checked + .slider {
      background-color: #68971c !important;
      &:before {
        left: -6.5px !important;
      }
    }
    .slider:before {
      width: 16px;
      height: 16px;
    }
  }

  .notif-radio-group {
    margin: 0 !important;
  }

  .notif-radio-button {
    margin: 0px !important;
    padding: 3px;
    width: fit-content;
  }

  .radio-btn-col {
    display: grid;
    width: 100%;
  }

  .notif-claim-value label {
    margin: 0;
  }

  .notif-wrapper-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 3rem;
    grid-template-areas:
      "notif_title-1 notif_title-2"
      "notif_comp-1 notif_comp-2";
  }

  .notif_title-1 {
    grid-area: notif_title-1;
  }
  .notif_title-2 {
    grid-area: notif_title-2;
  }
  .notif_comp-1 {
    grid-area: notif_comp-1;
  }
  .notif_comp-2 {
    grid-area: notif_comp-2;
  }

  .check-notif_value {
    padding-top: 12.85px;
    padding-bottom: 12.85px;
  }

  #saveSettingBtn {
    @include btn-default-styles;
  }
}

@media only screen and (max-width: 991px) {
  .notification-settings {
    padding: 0 !important;

    .account-profile-container {
      margin-left: 0;
    }

    .page-title {
      margin-left: 0;

      h1 {
        font-size: 32px;
      }
    }

    h4.title {
      background-color: transparent !important;
    }

    .btn-actions {
      flex-direction: row-reverse !important;
      justify-content: flex-end;
      padding-left: 0 !important;

      button {
        max-width: 300px;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .notification-settings {
    #saveSettingBtn {
      width: 100%;
    }

    .notif-wrapper-grid {
      grid-template-columns: 1fr !important;
      grid-template-rows: auto auto auto auto !important;
      grid-template-areas:
        "notif_title-1"
        "notif_comp-1"
        "notif_title-2"
        "notif_comp-2" !important;
    }

    .notif_title-1 {
      margin-bottom: 0.5rem;
    }

    .notif_title-2 {
      margin-top: 2rem;
    }

    .notif_comp-1 {
      .notif-claim-value {
        border: 1px solid #bfddea;
        padding-bottom: 10px;
        border-radius: 8px;
        margin-bottom: 12px;
        padding: 13px 16px;
        font-weight: 700;
      }
    }
    .notif-claim-value:has(.mat-radio-checked) {
      background-color: #f2f8fb;
    }
    .radio-btn-col.notif-claim-value:last-child {
      border: 1px solid #bfddea !important;
    }

    .notif-radio-button {
      width: 100%;
      .mat-radio-label {
        flex-direction: row-reverse;
        .mat-radio-label-content {
          flex: 1;
        }
      }
    }
  }

  .notif-claim-value-wrapper {
    .switch {
      flex: none;
    }
  }
}
