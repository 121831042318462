$background-color: #f2f5f7;
$border: 1px solid rgb(171, 181, 189);
$error: #dc3545;

.custom-date-picker {
  .calendar {
    img {
      position: relative;
      top: -1px;
    }
  }
  .input-group {
    // width: auto;
    // max-width: 170px;
    .input-group-prepend {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-left: $border;
      border-top: $border;
      border-right: $border;
      border-bottom: $border;
      button {
        background-color: $background-color;
        &:focus,
        &:active {
          box-shadow: none !important;
          outline: 0 !important;
        }
      }
    }
    .date-input {
      border-left: 0px !important;
    }
    .clear-button {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      /** For some reason, `.input-group` sets z-index up to 4.
      This is to override that no matter what index the input-group is. */
      z-index: 5;
    }
  }

  .input-group-prepend.invalid,
  input.invalid {
    border-color: $error;
  }

  input {
    padding-left: 2px !important;
    height: 43px;
    border: $border;
    // background-color: $background-color;
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
  input[readonly] {
    @extend input;
  }

  .time-picker {
    white-space: nowrap;
    display: flex;

    .time-text {
      max-width: 155px !important;
    }
  }
}

#time-picker-wrapper {
  z-index: 99999999 !important;
  .time-picker-footer {
    button {
      padding-left: 20px !important;
      padding-right: 20px !important;
      &.atp-ref-dialog-close {
        background-color: #003765 !important;
        min-width: 40px;
        text-align: center;
        color: #fff;
      }
    }
  }
}
$border-alternate: 1px solid rgb(202, 202, 202);
/** Alternate style for `.webreq-custom-date-picker` for advanced search */
.advanced-search-alternate-date-picker {
  .input-group {
    width: 100%;
    max-width: inherit;
    border-bottom: none;
    .input-group-prepend {
      border: none;
      button {
        padding-left: 2px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: $border-alternate;
        border-bottom-left-radius: 0;
      }
    }
    .date-input {
      height: initial;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: $border-alternate;
      background-color: transparent;
      padding: 0;
      border-bottom-right-radius: 0;
      &::placeholder {
        color: #afafaf;
        font-style: italic;
      }
    }
  }
}

.complete-date-input {
  .custom-date-picker {
    .input-group {
      width: 100%;
      max-width: 100%;
      box-shadow: 0 1px 5px 0 rgb(0 0 0 / 13%);
    }
  }
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
